<template>
  <div>
    <Loader v-if="loaderEnabled" v-bind:logo="loaderLogo"></Loader>
    <div class="row">
      <div class="col-md-12">
        <KTCodePreview v-bind:title="'Danh phiếu không phục vụ được'">
          <template v-slot:toolbar>
            <div class="row">
              <div class="col-md-5">
                <b-dropdown size="sm" id="dropdown-1" right>
                  <template slot="button-content">
                    <i style="font-size: 1rem" class="fas fa-cog"></i>
                    <span class="font-weight-bolder">Thao tác</span>
                  </template>
                  <b-dropdown-item @click="excelModal">
                    <span>
                      <i style="font-size: 1rem" class="far fa-file-excel"></i>
                      &nbsp; Xuất Excel</span
                    >
                  </b-dropdown-item>
                </b-dropdown>
              </div>
            </div>
          </template>
          <template v-slot:preview>
            <b-row class="mb-5">
              <b-col md="3">
                <b-input-group>
                  <date-picker
                    placeholder="Từ ngày"
                    class="form-control form-control-sm"
                    :config="dpConfigs.date"
                    v-model="dateFrom"
                  ></date-picker>
                </b-input-group>
              </b-col>
              <b-col md="3">
                <b-input-group>
                  <date-picker
                    placeholder="Tới ngày"
                    class="form-control form-control-sm"
                    :config="dpConfigs.date"
                    v-model="dateTo"
                  ></date-picker>
                </b-input-group>
              </b-col>
            </b-row>
            <b-row class="mb-5">
              <b-col md="3">
                <BDropdownCustom
                  v-model="valueStore"
                  :options="optionsStore"
                  :placeholder="'cửa hàng'"
                  :searchable="true"
                  :matchKey="['name']"
                  :limit="5"
                />
              </b-col>
              <b-col md="3">
                <b-form-select
                  id="input-3"
                  size="sm"
                  v-model="selectedReason"
                  :options="listReason"
                  required
                  value-field="id"
                  text-field="name"
                  disabled-field="notEnabled"
                >
                  <template v-slot:first>
                    <b-form-select-option :value="null" disabled
                      >-- Lý do: --</b-form-select-option
                    >
                  </template>
                </b-form-select>
              </b-col>
              <b-col md="3">
                <vue-autosuggest
                  v-model="searchEmployee"
                  :suggestions="filteredOptions"
                  @selected="onSelected"
                  :limit="10"
                  @input="onInputChange"
                  :input-props="{
                    id: 'autosuggest__input',
                    placeholder: 'Nhập tên nhân viên',
                  }"
                  ><div
                    slot-scope="{ suggestion }"
                    style="display: flex; align-items: center"
                  >
                    {{ suggestion.item.suggestionName }}
                  </div>
                </vue-autosuggest>
              </b-col>
              <b-col md="3">
                <b-input
                  size="sm"
                  placeholder="Tên sản phẩm"
                  v-model="search"
                  append-icon="search"
                  single-line
                  hide-details
                ></b-input>
              </b-col>
            </b-row>
            <b-row class="mb-5">
              <b-col md="1">
                <b-button
                  style="fontweight: 600; width: 70px"
                  variant="primary"
                  size="sm"
                  @click="onFilter"
                  >Lọc</b-button
                >
              </b-col>
            </b-row>
            <table
              class="table table-bordered table-vertical-center table-hover"
            >
              <thead>
                <tr>
                  <th style="textalign: center; width: 40px">STT</th>
                  <th scope="col">Tên Khách Hàng</th>
                  <th scope="col" style="width: 95px">SĐT</th>
                  <th scope="col" style="width: 125px">Chi Nhánh</th>
                  <th scope="col" style="width: 15%">Sản Phẩm</th>
                  <th scope="col" style="width: 15%">Lý do</th>
                  <th scope="col" style="width: 15%">Note</th>
                  <th scope="col" style="width: 125px">Nhân Viên</th>
                  <th scope="col" style="width: 95px">Ngày tạo</th>
                </tr>
              </thead>

              <tbody>
                <template v-for="(item, index) in desserts">
                  <template v-for="(product, j) in item.products">
                    <tr
                      :data="`row_${item.id}`"
                      class="heighRow"
                      :key="index + j"
                      @mouseover="hoverRow"
                      @mouseleave="outRow"
                    >
                      <td
                        :rowspan="item.products.length"
                        v-if="j === 0 ? true : false"
                      >
                        <div class="d-flex justify-content-center">
                          <span v-text="item.stt" class="text-right"></span>
                        </div>
                      </td>
                      <td
                        class="textWidth"
                        :rowspan="item.products.length"
                        v-if="j === 0 ? true : false"
                      >
                        <span>{{ item.customerName }}</span>
                      </td>
                      <td
                        class="textWidth"
                        :rowspan="item.products.length"
                        v-if="j === 0 ? true : false"
                      >
                        <span style="font-weight: bold">{{
                          item.customerPhone
                        }}</span>
                      </td>
                      <td
                        class="textWidth"
                        :rowspan="item.products.length"
                        v-if="j === 0 ? true : false"
                      >
                        <span>{{ item.storeName }}</span>
                      </td>
                      <td class="textWidth">
                        <span>{{ product ? product.productName : '' }}</span>
                      </td>
                      <td class="textWidth">
                        <span>{{
                          product && product.reason ? product.reason.name : ''
                        }}</span>
                      </td>
                      <td
                        class="textWidth"
                        :rowspan="item.products.length"
                        v-if="j === 0 ? true : false"
                      >
                        <span>{{ item.description }}</span>
                      </td>
                      <td
                        class="textWidth"
                        :rowspan="item.products.length"
                        v-if="j === 0 ? true : false"
                      >
                        <span>{{ item.employeeName }}</span>
                      </td>
                      <td
                        class="textWidth"
                        :rowspan="item.products.length"
                        v-if="j === 0 ? true : false"
                      >
                        <span style="color: rgb(54, 153, 255)">{{
                          item.createdAt
                        }}</span>
                      </td>
                    </tr>
                  </template>
                </template>
              </tbody>
            </table>
            <b-row>
              <b-col>
                <p class="mt-3 text-dark" style="padding: 0; font-weight: 500">
                  Tổng số: {{ totalItem }}
                </p>
              </b-col>
              <b-col>
                <b-pagination-nav
                  class="customPagination"
                  v-if="numberOfPage >= 2"
                  :link-gen="linkGen"
                  :number-of-pages="numberOfPage"
                  use-router
                  @change="fetchOrderResource"
                  align="right"
                  first-class="page-item-first btn btn-icon btn-sm mr-1 my-1"
                  prev-class="page-item-prev btn btn-icon btn-sm mr-.5 my-1"
                  next-class="page-item-next btn btn-icon btn-sm mr-1 my-1 ml-.5"
                  last-class="page-item-last btn btn-icon btn-sm my-1 "
                  page-class="btn btn-icon btn-sm border-0 mr-.5 my-1"
                >
                  <template v-slot:first-text>
                    <span>
                      <i class="ki ki-bold-double-arrow-back icon-xs"></i>
                    </span>
                  </template>

                  <template v-slot:prev-text>
                    <i class="ki ki-bold-arrow-back icon-xs"></i>
                  </template>

                  <template v-slot:next-text>
                    <i class="ki ki-bold-arrow-next icon-xs"></i>
                  </template>

                  <template v-slot:last-text>
                    <span class="text-info">
                      <i class="ki ki-bold-double-arrow-next icon-xs"></i>
                    </span>
                  </template>
                </b-pagination-nav>
              </b-col>
            </b-row>
          </template>
        </KTCodePreview>
        <b-modal
          ref="excel-modal"
          hide-footer
          title="Xuất excel"
          id="excel-modal"
        >
          <v-form ref="form" lazy-validation>
            <div class="mb-4">
              <div class="row">
                <div class="col-6">
                  <b-form-group label="Chọn cột cần xuất:">
                    <b-form-radio-group
                      id="radio-group-excel-1"
                      v-model="selectedRowExcel"
                      name="radio-sub-component-1"
                      @change.native="showHeader"
                    >
                      <b-form-radio value="all">Tất cả</b-form-radio>
                    </b-form-radio-group>
                  </b-form-group>
                  <b-form-group label="Danh sách cần xuất:">
                    <b-form-radio-group
                      id="radio-group-excel-2"
                      v-model="selectedListExcel"
                      name="radio-sub-component-2"
                    >
                      <b-form-radio value="all">Tất cả các trang</b-form-radio>
                    </b-form-radio-group>
                  </b-form-group>
                </div>
              </div>
            </div>

            <b-button
              style="width: 110px"
              variant="primary"
              size="sm"
              @click="reportClick"
              class="mr-3"
            >
              <v-icon small class="text-white" v-b-tooltip>mdi-download</v-icon>
              <strong>Xuất Excel</strong>
            </b-button>

            <b-button
              style="width: 100px"
              variant="secondary"
              size="sm"
              @click="$bvModal.hide('excel-modal')"
            >
              <strong>Hủy</strong>
            </b-button>
          </v-form>
        </b-modal>
      </div>
    </div>
  </div>
</template>

<script>
import KTCodePreview from '@/view/content/CodePreview.vue';
import { SET_BREADCRUMB } from '@/core/services/store/modules/breadcrumbs.module';
import JwtService from '@/core/services/jwt.service';
import SavaDataUtil from './../../../utils/saveDataToLocal';
import Loader from '@/view/content/Loader.vue';
import moment from 'moment';
import ApiService from '@/core/services/api.service';
import datePicker from 'vue-bootstrap-datetimepicker';
import { createFile, getFileName } from './../../../utils/file-download';
import { BASE_URL } from '../../../utils/constants';
import axios from 'axios';
import 'pc-bootstrap4-datetimepicker/build/css/bootstrap-datetimepicker.css';
import '@fortawesome/fontawesome-free/css/fontawesome.css';
import '@fortawesome/fontawesome-free/css/regular.css';
import '@fortawesome/fontawesome-free/css/solid.css';
import jQuery from 'jquery';

jQuery.extend(true, jQuery.fn.datetimepicker.defaults, {
  icons: {
    time: 'far fa-clock',
    date: 'far fa-calendar',
    up: 'fas fa-arrow-up',
    down: 'fas fa-arrow-down',
    previous: 'fas fa-chevron-left',
    next: 'fas fa-chevron-right',
    today: 'fas fa-calendar-check',
    clear: 'far fa-trash-alt',
    close: 'far fa-times-circle',
  },
});
import BDropdownCustom from '@/view/base/bootstrap/BDropdownCustom.vue';

export default {
  data() {
    return {
      dpConfigs: {
        date: {
          format: 'DD/MM/YYYY',
          useCurrent: false,
          showClear: true,
          showClose: true,
          minDate: false,
          maxDate: false,
          locale: 'vi',
          sideBySide: true,
        },
        start: {
          format: 'DD/MM/YYYY',
          useCurrent: false,
          showClear: true,
          showClose: true,
          minDate: false,
          maxDate: false,
          locale: 'vi',
          sideBySide: true,
        },
        end: {
          format: 'DD/MM/YYYY',
          useCurrent: false,
          showClear: true,
          showClose: true,
          minDate: false,
          maxDate: false,
          locale: 'vi',
          sideBySide: true,
        },
      },
      dateFrom: moment().subtract(15, 'days').format('DD/MM/YYYY'),
      dateTo: moment().format('DD/MM/YYYY'),
      listReason: [
        {
          id: null,
          name: 'Chọn lý do',
        },
      ],
      selectedReason: null,
      selectedEmployee: null,
      valueStore: [],
      optionsStore: [],
      btnCreate: {
        fontWeight: '600!important',
        'margin-right': '-10px',
      },
      search: '',
      searchEmployee: '',
      filteredOptions: [],
      desserts: [],
      itemChoice: null,
      listFeature: [],
      linkGen: (pageNum) => {
        return pageNum === 1 ? '?' : `?page=${pageNum}`;
      },
      page: 1,
      pageSize: 30,
      numberOfPage: null,
      totalItem: 0,
      options: [
        {
          data: [],
        },
      ],
      selectedRowExcel: 'all',
      selectedListExcel: 'all',
    };
  },
  components: {
    KTCodePreview,
    Loader,
    datePicker,
    BDropdownCustom,
  },
  created() {
    this.fetchEmployee();
    this.getListFeature();
    this.fetchStore();
    this.fetchReasons();
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: 'Phiếu không phục vụ được', route: '/order-cant-serve' },
    ]);
    this.getFillter();
    this.page = this.$route.query.page;
    if (!this.page) {
      this.page = 1;
    }
  },
  methods: {
    hoverRow: function (evt) {
      const items = jQuery(evt.currentTarget);
      const dataId = jQuery(items[0]).attr('data');
      const rows = jQuery(`[data=${dataId}]`);
      rows.addClass('tr-rowspan-hover');
    },
    outRow: function (evt) {
      const items = jQuery(evt.currentTarget);
      const dataId = jQuery(items[0]).attr('data');
      const rows = jQuery(`[data=${dataId}]`);
      rows.removeClass('tr-rowspan-hover');
    },
    onInputChange(text) {
      if (!text) {
        text = '';
      }
      this.searchEmployee = text;
      /* Full control over filtering. Maybe fetch from API?! Up to you!!! */
      const filteredData = this.options[0].data
        .filter((item) => {
          return (
            item.nameNoneSign.toLowerCase().indexOf(text.toLowerCase()) > -1 ||
            item.name.toLowerCase().indexOf(text.toLowerCase()) > -1 ||
            item.code.toLowerCase().indexOf(text.toLowerCase()) > -1
          );
        })
        .slice(0, this.limit);

      this.filteredOptions = [
        {
          data: filteredData,
        },
      ];
    },
    onSelected(option) {
      this.selectedEmployee = option.item.id;
      this.searchEmployee = option.item.name;
    },
    fetchEmployee: async function () {
      this.options[0].data = [];
      ApiService.setHeader();
      ApiService.get('/employees/get-list-filter').then((response) => {
        const stores = response.data.data;
        stores.map((element) => {
          let suggestionName = element.name;
          if (element.code) {
            suggestionName += ' ( ' + element.code + ')';
          }
          let nameNoneSign = this.xoa_dau(element.name);
          let store = {
            id: element.id,
            name: element.name,
            code: element.code,
            suggestionName: suggestionName,
            nameNoneSign: nameNoneSign,
          };
          this.options[0].data.push(store);
        });
        this.filteredOptions = [{ data: this.options[0].data }];
      });
    },
    xoa_dau(str) {
      str = str.replace(/à|á|ạ|ả|ã|â|ầ|ấ|ậ|ẩ|ẫ|ă|ằ|ắ|ặ|ẳ|ẵ/g, 'a');
      str = str.replace(/è|é|ẹ|ẻ|ẽ|ê|ề|ế|ệ|ể|ễ/g, 'e');
      str = str.replace(/ì|í|ị|ỉ|ĩ/g, 'i');
      str = str.replace(/ò|ó|ọ|ỏ|õ|ô|ồ|ố|ộ|ổ|ỗ|ơ|ờ|ớ|ợ|ở|ỡ/g, 'o');
      str = str.replace(/ù|ú|ụ|ủ|ũ|ư|ừ|ứ|ự|ử|ữ/g, 'u');
      str = str.replace(/ỳ|ý|ỵ|ỷ|ỹ/g, 'y');
      str = str.replace(/đ/g, 'd');
      str = str.replace(/À|Á|Ạ|Ả|Ã|Â|Ầ|Ấ|Ậ|Ẩ|Ẫ|Ă|Ằ|Ắ|Ặ|Ẳ|Ẵ/g, 'A');
      str = str.replace(/È|É|Ẹ|Ẻ|Ẽ|Ê|Ề|Ế|Ệ|Ể|Ễ/g, 'E');
      str = str.replace(/Ì|Í|Ị|Ỉ|Ĩ/g, 'I');
      str = str.replace(/Ò|Ó|Ọ|Ỏ|Õ|Ô|Ồ|Ố|Ộ|Ổ|Ỗ|Ơ|Ờ|Ớ|Ợ|Ở|Ỡ/g, 'O');
      str = str.replace(/Ù|Ú|Ụ|Ủ|Ũ|Ư|Ừ|Ứ|Ự|Ử|Ữ/g, 'U');
      str = str.replace(/Ỳ|Ý|Ỵ|Ỷ|Ỹ/g, 'Y');
      str = str.replace(/Đ/g, 'D');
      return str;
    },
    customLabelStore(option) {
      return `${option.name}`;
    },
    onSelectStore(option) {
      let index = this.optionsStore.findIndex(
        (item) => item.name == option.name,
      );
      this.optionsStore[index].checked = true;
    },
    onRemoveStore(option) {
      let index = this.optionsStore.findIndex(
        (item) => item.name == option.name,
      );
      this.optionsStore[index].checked = false;
    },
    fetchStore: async function () {
      this.optionsStore = [];
      ApiService.setHeader();
      ApiService.get(`/stores/getStores`).then((response) => {
        const stores = response.data.data;
        stores.map((element) => {
          let option = {
            code: element.id,
            name: element.name,
            shortName: element.shortName,
            checked: false,
          };
          this.optionsStore.push(option);
        });
        this.getFillter();
        this.fetchOrderResource();
      });
    },
    fetchReasons: async function () {
      this.optionsStore = [];
      ApiService.setHeader();
      ApiService.get(`order-cant-serve/reasons`).then((response) => {
        this.listReason = response.data.data;
      });
    },
    onFilter() {
      this.$route.query.page = 1;
      this.changeURL();
      this.fetchOrderResource();
    },
    setFillter() {
      const queryObj = {};

      // parse list store id
      const listStoreId = (this.optionsStore || [])
        .filter((element) => element.checked)
        .map((element) => element.code);
      if (listStoreId.length > 0) {
        queryObj.listStore = listStoreId;
      }

      // parse reason id
      if (this.selectedReason || this.selectedReason === 0) {
        queryObj.reason = this.selectedReason;
      }

      // parse employee id
      if (this.selectedEmployee || this.selectedEmployee === 0) {
        queryObj.employee = this.selectedEmployee;
      }

      // parse product name
      if (this.search) {
        queryObj.productName = this.search;
      }
      return queryObj;
    },
    changeURL() {
      let queryObj = this.setFillter();
      this.$router.push({
        name: 'order-cant-serve',
        query: queryObj,
      });
    },
    getListFeature() {
      this.listFeature = SavaDataUtil.getData('listFeature');
    },
    checkPermission(condition) {
      if (this.listFeature.indexOf(condition) > -1) {
        return true;
      } else {
        return false;
      }
    },
    addRow() {
      let data = {
        id: '',
        name: '',
        createAt: '',
      };
      if (this.desserts[0].name != '') {
        this.desserts.unshift(data);
      }
    },
    cancelAdd() {
      this.desserts.shift();
    },
    getFillter() {
      this.selectedEmployee = this.$route.query.employee || null;
      this.selectedReason = this.$route.query.reason || null;
      this.search = this.$route.query.productName || null;
      this.listStoreId = this.$route.query.listStore || null;
      if (Array.isArray(this.listStoreId)) {
        this.listStoreId.forEach((id) => {
          const index = this.optionsStore.findIndex((item) => item.code == id);
          if (index > -1) {
            this.optionsStore[index].checked = true;
            this.valueStore.push(this.optionsStore[index]);
          }
        });
      } else if (this.listStoreId) {
        const index = this.optionsStore.findIndex(
          (item) => item.code == this.listStoreId,
        );
        if (index > -1) {
          this.optionsStore[index].checked = true;
          this.valueStore.push(this.optionsStore[index]);
        }
      }
    },
    excelModal: async function () {
      this.$refs['excel-modal'].show();
    },
    reportClick: function () {
      if (!this.searchEmployee) {
        this.selectedEmployee = null;
      }
      const listStoreId = this.valueStore.map(({ code }) => code);

      const params = {
        storeIds: listStoreId.length > 0 ? listStoreId : null,
        reasonId: this.selectedReason,
        employeeId: this.selectedEmployee,
        productName: this.search,
        fromDate: this.dateFrom
          ? moment(this.dateFrom, 'DD/MM/YYYY').format('yyyy-MM-DD')
          : null,
        toDate: this.dateTo
          ? moment(this.dateTo, 'DD/MM/YYYY').format('yyyy-MM-DD')
          : null,
      };

      axios({
        params,
        url: `${BASE_URL}order-cant-serve/export-excel`,
        method: 'get',
        responseType: 'blob',
        headers: {
          Authorization: `Bearer ${JwtService.getToken()}`,
        },
      })
        .then((response) => {
          const fileName = getFileName(response);
          createFile(response.data, fileName);
        })
        .catch((err) => {
          console.log('exportExcel error', err);
        });
    },
    fetchOrderResource() {
      this.page = this.$route.query.page;
      if (!this.page) {
        this.page = 1;
      }

      if (!this.searchEmployee) {
        this.selectedEmployee = null;
      }
      const listStoreId = this.valueStore.map(({ code }) => code);

      const params = {
        size: this.pageSize,
        page: this.page,
        storeIds: listStoreId.length > 0 ? listStoreId : null,
        reasonId: this.selectedReason,
        employeeId: this.selectedEmployee,
        productName: this.search,
        fromDate: this.dateFrom
          ? moment(this.dateFrom, 'DD/MM/YYYY').format('yyyy-MM-DD')
          : null,
        toDate: this.dateTo
          ? moment(this.dateTo, 'DD/MM/YYYY').format('yyyy-MM-DD')
          : null,
      };

      ApiService.setHeader();
      ApiService.query('order-cant-serve', { params }).then(({ data }) => {
        this.numberOfPage = data.data.total_page;
        this.totalItem = data.data.total_row;

        this.desserts = data.data.data.map((item, index) => {
          item.productName = '';
          item.reason = '';
          item.stt = index + 1 + (this.page - 1) * this.pageSize;
          if (item.products && item.products.length) {
            item.productName = item.products[0].productName;
            item.reason =
              item.products[0].reason && item.products[0].reason.name;
          }
          item.createdAt = moment(item.createdAt).format('DD-MM-YYYY');
          return item;
        });
      });
    },
  },
};
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style>
.customPagination /deep/ .page-item-first .page-link {
  background-color: #ecf1f6;
}

.customPagination /deep/ .page-item-prev .page-link {
  background-color: #ecf1f6;
}
.customPagination /deep/ .page-item-next .page-link {
  background-color: #ecf1f6;
}
.customPagination /deep/ .page-item-last .page-link {
  background-color: #ecf1f6;
}

.customPagination /deep/ .page-item .page-link {
  border-radius: 0.42rem;
  font-size: 0.9rem;
  line-height: 1rem;
  font-weight: 600;
  justify-content: center;
  align-items: center;
  -webkit-box-align: center;
  height: 2.25rem;
  min-width: 2.25rem;
  padding: 0.5rem;
  color: #7e8299;
}

.customPagination /deep/ .page-item {
  color: #ecf1f6;
}

.customPagination /deep/ .page-item .router-link-exact-active {
  color: #fff;
}

.customPagination /deep/ .page-item .page-link:hover {
  background-color: #3699ff;
  color: #fff;
  transition: color 0.15s ease, background-color 0.15s ease,
    border-color 0.15s ease, box-shadow 0.15s ease,
    -webkit-box-shadow 0.15s ease;
  opacity: 1;
}

.icon:hover {
  background-color: #90c6fc;
}

.multiselect {
  min-height: initial;
}
.multiselect__tags {
  font-size: 12px !important;
  display: block;
  padding: 3px 40px 0 8px;
  border-radius: 5px;
  border: 1px solid #e8e8e8;
  background: #fff;
  min-height: initial;
}
.multiselect__element {
  min-height: initial;
  display: block;
  background: #fff;
  font-size: 12px;
}
.multiselect__input {
  min-height: initial;
  display: block;
  background: #fff;
  font-size: 12px;
}
.multiselect__single {
  font-size: 12px;
}

.autosuggest__results ul {
  list-style: none;
  cursor: pointer;
  margin-bottom: 0;
}

.autosuggest__results {
  position: absolute;
  background-color: #fff;
  z-index: 1;
  width: 100%;
  border: 1px solid #e0e0e0;
  overflow: scroll;
  max-height: 200px;
  position: absolute;
}

.autosuggest__results-container {
  position: relative;
  width: 100%;
}

#autosuggest__input {
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #e5eaee;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  height: calc(1.35em + 1.1rem + 2px);
  padding: 0.55rem 0.75rem;
  font-size: 0.925rem;
  line-height: 1.35;
  border-radius: 0.28rem;
}

#autosuggest__input:focus {
  outline: none !important;
  border: 1px solid #92c5f9;
}

.autosuggest__results-item:hover {
  background-color: #ddd;
}

.autosuggest__results ul li {
  padding: 8px;
}

.test {
  position: absolute;
  right: 1vw;
}
.checkbox-label {
  display: block;
}
</style>
